//general
import React from "react"
//styles
import * as styles from "./styles.module.scss"

const CookiePolicy = ({ cookieData }) => {
  const data = cookieData.html

  return (
    <section
      className={styles.policy}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}

export default CookiePolicy
